<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>eWallet</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active">eWallet</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <a
              class="btn btn-primary"
              href="javascript:void()"
              data-toggle="modal"
              data-target="#charge"
            >
              Charge
            </a>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped verticle-middle table-responsive-sm"
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">In</th>
                    <th scope="col">Out</th>
                    <th scope="col">Balance</th>
                    <th scope="col">QrCode</th>
                  </tr>
                </thead>
                <tbody v-if="students">
                  <tr v-for="student in students" :key="student.id">
                    <td>{{ student.name }}</td>
                    <td style="color: green">{{ student.add ?? 0 }}</td>
                    <td style="color: red">{{ student.deduct ?? 0 }}</td>
                    <td>{{ student.add - student.deduct }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'eWallet-qrCode',
                          query: { student_id: student.id },
                        }"
                        title="QrCode"
                        class="mr-4"
                        ><i class="fa fa-eye color-muted"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="6">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Amount -->
    <div
      class="modal fade"
      id="charge"
      tabindex="-1"
      role="dialog"
      aria-labelledby="chargeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="chargeLabel">Charge</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="" id="chargeCreditForm">
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Student</label>
                <div class="col-md-9">
                  <select
                    v-if="students"
                    class="form-control"
                    name="student_id"
                    required
                  >
                    <option value="null" disabled selected>
                      Nothing Selected
                    </option>
                    <option
                      v-for="student in students"
                      :key="student.id"
                      :value="student.id"
                    >
                      {{ student.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Amount</label>
                <div class="col-md-9">
                  <input
                    type="number"
                    name="amount"
                    required
                    class="form-control"
                    placeholder="Amount"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              id="close"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              :disabled="disableBtn"
              class="btn btn-primary"
              @click.prevent="charge()"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useStudentFinancialTransactions from "@/composables/student_financial_transactions";
import { inject, ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  setup() {
    const hasAnyRole = inject("hasAnyRole");
    const store = useStore();
    const swal = inject("$swal");
    const router = useRouter();
    const disableBtn = ref(false);

    const { chargeNow, students, getParentStudentsList } =
      useStudentFinancialTransactions();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasAnyRole("Parent,Student")) {
        router.push({ name: "401" });
      } else {
        getParentStudentsList();
      }
    });

    const charge = async () => {
      var formDataObj = new FormData();
      const form = document.querySelector("#chargeCreditForm");
      Array.from(form.elements).forEach((input) => {
        formDataObj.append(input.name, input.value);
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      await chargeNow(formDataObj).then((resp) => {
        swal.close();
        if (resp != "fail") {
          store.dispatch("setUserPaymentRequest", resp);
          document.getElementById("close").click();
          router.push({
            name: "Student-Payments-Payment-Confirmation",
            params: {
              userrequest_id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };
    return {
      charge,
      students,
      disableBtn,
    };
  },
};
</script>
